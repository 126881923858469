//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MatchHeatTable from '@/components/DeliveryResults/Graphics/MatchHeatTable.vue'

export default {
  name: 'EmployabilityProductivityTab',
  components: {
    MatchHeatTable,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
}
