//
//
//
//

import * as echarts from 'echarts/core'
import { TooltipComponent, LegendComponent } from 'echarts/components'
import { BarChart } from 'echarts/charts'
import { LabelLayout } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'

echarts.use([
  TooltipComponent,
  LegendComponent,
  BarChart,
  CanvasRenderer,
  LabelLayout,
])

export default {
  name: 'BarChart',
  props: {
    /**
     * Required to render the chart
     */
    chartId: {
      type: String,
      default: 'graphic',
    },
    /**
     * Follow Echart Pie option
     */
    option: {
      type: Object,
      default: () => ({}),
    },
    height: {
      type: String,
      default: '400px',
    },
    width: {
      type: String,
      default: '526px',
    },
  },
  data() {
    return {
      myChart: null,
      resizeObserver: null,
    }
  },
  mounted() {
    const chartDom = document.getElementById(this.chartId)
    this.resizeObserver = new ResizeObserver(this.resize).observe(chartDom)
    this.myChart = echarts.init(chartDom)
    window.addEventListener('resize', this.resize)
    this.myChart.setOption(this.option)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }
  },
  methods: {
    resize() {
      this.myChart.resize()
    },
  },
}
