var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{attrs:{"tag":"form","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('b-modal',{attrs:{"can-cancel":['escape', 'outside']},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('modal-with-title-bar',{staticClass:"form-modal-container",attrs:{"title":_vm.title,"close":_vm.close},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"is-justify-content-flex-end"},[_c('Button',{attrs:{"type":"is-text","rounded":""},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_vm._v(" "),_c('Button',{attrs:{"type":"is-primary","rounded":"","disabled":invalid},on:{"click":function($event){return handleSubmit(_vm.onSubmit)}}},[_vm._v("\n            "+_vm._s(_vm.textAction)+"\n          ")])],1)]},proxy:true}],null,true)},[_c('div',{staticClass:"modal-content"},[_c('p',{staticClass:"has-text-grey"},[_vm._v("\n          "+_vm._s(_vm.description)+"\n        ")]),_vm._v(" "),_c('FormSelect',{staticClass:"mt-5 occupation-select",attrs:{"rules":'required',"label":"title","options":_vm.occupations,"placeholder":_vm.$t(
              'companies.requiredOccupations.createRequiredOccupation.occupationInput.placeholder'
            ),"label-field":_vm.$t(
              'companies.requiredOccupations.createRequiredOccupation.occupationInput.label'
            )},on:{"select":function($event){_vm.defaultForm.occupation = $event}},model:{value:(_vm.defaultForm.occupation),callback:function ($$v) {_vm.$set(_vm.defaultForm, "occupation", $$v)},expression:"defaultForm.occupation"}})],1)])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }