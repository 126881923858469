const Request = require('@/repositories/request')
const qs = require('qs')

class Project extends Request {
  save(body) {
    return this.$strapi.$http.$post('/projects', body)
  }

  update(id, data) {
    return this.$strapi.$projects.update(id, data)
  }

  findOne(id, params) {
    const query = qs.stringify(params, { encode: false })
    return this.$strapi.$http.$get(`/projects/${id}?${query}`)
  }

  findAll(params) {
    const stringifyParams = qs.stringify({ ...params }, { encode: false })
    return this.$strapi.$projects.find(stringifyParams)
  }

  findOccupationsByCompany(companyId) {
    return this.$strapi.$http.$get(`/projects/${companyId}/occupations`)
  }

  getPotentialEmployeesByOccupation(projectId, occupationId) {
    return this.$strapi.$http.$get(
      `/projects/${projectId}/occupation/${occupationId}`
    )
  }

  getEmployeesAndInvitationByProject(projectId) {
    return this.$strapi.$http.$get(
      `/projects/${projectId}/employees-by-project`
    )
  }

  async downloadReportExecutiveProject(projectId) {
    const file = await this.$strapi.$http.get(
      `/projects/reports/executive/${projectId}`
    )
    return file.blob()
  }

  async downloadProjectReportfSkillsToBeWorked(projectId) {
    const file = await this.$strapi.$http.get(
      `/projects/reports/skills-to-be-worked/${projectId}`
    )
    return file.blob()
  }

  async downloadReportMatchCoursesProject(projectId) {
    const file = await this.$strapi.$http.get(
      `/projects/reports/match-course/${projectId}`
    )
    return file.blob()
  }

  async downloadReportModelsProject(projectId) {
    const file = await this.$strapi.$http.get(
      `/projects/reports/models/${projectId}`
    )
    return file.blob()
  }

  async downloadProjectReportExperiences(projectId) {
    const file = await this.$strapi.$http.get(
      `/projects/reports/experiences/${projectId}`
    )
    return file.blob()
  }

  async downloadReportSkillsInventoryProject(projectId) {
    const file = await this.$strapi.$http.get(
      `/projects/reports/skills-inventory/${projectId}`
    )
    return file.blob()
  }

  async downloadReportCompanyProject(projectId) {
    const file = await this.$strapi.$http.get(
      `/projects/reports/operation/${projectId}`
    )
    return file.blob()
  }

  delete(id) {
    return this.$strapi.$projects.delete(id)
  }

  saveReports(projectId) {
    return this.$strapi.$http.$get(`/projects/reports/save/${projectId}`)
  }

  searchOrigins({ text, companyId, companyType, hideMapha = false }) {
    const query = qs.stringify(
      { text, companyId, companyType, hideMapha },
      { encode: false }
    )
    return this.$strapi.$http.$get(`/projects/origins/search/?${query}`)
  }

  searchTarget(text = '', hideMapha = false, onlyCustom = false) {
    const query = qs.stringify(
      { text, hideMapha, onlyCustom },
      { encode: false }
    )
    return this.$strapi.$http.$get(`/projects/target/search/?${query}`)
  }

  async getProjectAndQuestionaries() {
    return await this.$strapi.$http.$get('/projects/user/questionaries')
  }

  async getKitSkills(projectId) {
    return await this.$strapi.$http.$get(`/projects/${projectId}/kit-skills`)
  }

  async getMatchTableData(projectId) {
    return await this.$strapi.$http.$get(`/projects/${projectId}/models-data`)
  }
}

module.exports = Project
