//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ColoredNotification',
  props: {
    description: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: true,
    },
    isWarning: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cssVars() {
      return {
        '--bg-color': this.isWarning ? '#FF3860' : '#209cee',
      }
    },
    iconOption() {
      return this.isWarning ? 'alert-outline' : 'information-outline'
    },
  },
}
