//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TableEmptyData from '@/components/Shared/Table/TableEmptyData'

export default {
  name: 'TopSkillsSection',
  components: {
    TableEmptyData,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      perPage: 10,
      paginated: false,
    }
  },
  computed: {
    columns() {
      return [
        {
          label: 'Habilidad',
          field: 'skill_name',
          sortable: true,
          cellClass: 'table-cells',
        },
        {
          label: 'Nº usuarios',
          field: 'skill_count',
          sortable: true,
          centered: true,
          width: '153',
        },
      ]
    },
  },
  mounted() {
    if (window.innerWidth <= 768) {
      this.perPage = 2
      this.paginated = true
    }
  },
}
