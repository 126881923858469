//
//
//
//
//
//
//
//
//
//
//

import EmptyDataIcon from '~/assets/svg/empty-data.svg'

export default {
  name: 'TableEmptyData',
  components: {
    EmptyDataIcon,
  },
}
