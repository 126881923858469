//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isMatch } from '@/util/string'
export default {
  props: {
    searchTerm: {
      type: String,
      default: '',
    },
    workers: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    visibleWorkers() {
      const workers = this.workers.filter(
        (worker) =>
          isMatch(this.searchTerm, `${worker.lastnames} ${worker.names}`) ||
          isMatch(this.searchTerm, worker.lastnames) ||
          isMatch(this.searchTerm, worker.names) ||
          isMatch(this.searchTerm, worker.roleDescription)
      )
      if (this.searchTerm !== '' && workers.length === 0) {
        this.$emit('not-results', true)
      } else {
        this.$emit('not-results', false)
      }
      return workers
    },
  },
}
