//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '@/components/Shared/Button'

export default {
  name: 'DownloadReportDropdown',
  components: {
    Button,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    downloadReportEmployeeData() {
      this.$emit('downloadReportEmployeeData')
    },
    downloadReportExecutive() {
      this.$emit('downloadReportExecutive')
    },
    downloadPdfReportExecutive() {
      this.$emit('downloadPdfReportExecutive')
    },
  },
}
