//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver } from 'vee-validate'
import ModalWithTitleBar from '~/components/Shared/ModalWithTitleBar.vue'
import Button from '~/components/Shared/Button'

export default {
  name: 'CreateOccupation',
  components: {
    ModalWithTitleBar,
    ValidationObserver,
    Button,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    occupations: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    textAction: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      defaultForm: {
        occupation: null,
      },
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    onSubmit() {
      this.$emit('submit', this.defaultForm.occupation)
    },
  },
}
