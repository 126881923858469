//
//
//
//
//
//
//
//
//
//

import GraphicCard from '~/components/DeliveryResults/GraphicCard.vue'

export default {
  name: 'ProjectsSection',
  components: {
    GraphicCard,
  },
  props: {
    projectsByStatus: {
      type: Object,
      required: true,
    },
  },
  computed: {
    graphicData() {
      const { totalDraft, totalActive, totalCompleted } = this.projectsByStatus
      if (totalDraft || totalActive || totalCompleted) {
        return [
          { value: totalDraft, name: 'En creación' },
          { value: totalActive, name: 'En curso' },
          { value: totalCompleted, name: 'Finalizados' },
        ]
      }
      return [
        { value: 0, name: 'En creación' },
        { value: 0, name: 'En curso' },
        { value: 0, name: 'Finalizados' },
      ]
    },
    colors() {
      if (!this.graphicData[0].value) {
        return ['#DBDBDB']
      }
      return ['#C1F6F1', '#2CD5C4', '#1FB7A8']
    },
    pieOption() {
      return {
        color: this.colors,
        tooltip: {
          trigger: 'item',
        },
        legend: {
          bottom: '0%',
          left: 'center',
        },
        series: [
          {
            type: 'pie',
            radius: ['45%', '70%'],
            label: {
              show: false,
            },
            data: this.graphicData,
          },
        ],
      }
    },
  },
}
